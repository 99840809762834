import { IsDate, IsNumber, IsString } from 'class-validator'

export class EtiquetaDto {
  @IsNumber()
  id!: number

  @IsString()
  etiqueta!: string

  @IsDate()
  fecha!: Date
}
