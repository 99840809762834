import { IsDate, IsNumber, IsString } from 'class-validator'

export class ClasificacionDto {
  @IsNumber()
  id!: number

  @IsDate()
  capturaFecha!: Date

  @IsString()
  nota!: string

  clasificacion!: DataClasificacionDto
}

export class DataClasificacionDto {
  @IsNumber()
  id!: number

  @IsString()
  descripcion!: string
}
