import { IsOptional } from 'class-validator'
import { CatalogoCiudadDto, CatalogoMunicipioDto, ZonaDto, CatalogoEstadoDto } from '@decet/catalogo-shared'

export class CatalogoDto {
  @IsOptional()
  id?: number
  descripcion!: string
}

export class DireccionDto {
  @IsOptional()
  id?: number
  @IsOptional()
  calle!: string
  numeroInterior?: number
  numeroExterior?: number
  zona!: ZonaDto | null
  municipio!: CatalogoMunicipioDto | null
  pais?: CatalogoDto | null
  estado!: CatalogoEstadoDto | null
  ciudad!: CatalogoCiudadDto | null
  delegacio!: CatalogoDto | null
  codigoPostal!: string
  latitud?: number
  longitud?: number
  entreCalles!: string
  etiqueta!: string
}
