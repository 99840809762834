import { Type } from 'class-transformer'
import { IsBoolean, IsDateString, IsEmail, IsNumber, IsOptional, IsString, ValidateNested } from 'class-validator'
import { ContactoDto, ProspectoTelefonoDto } from '@decet/crm-libreria-shared'
import { DireccionDto } from '@decet/direcciones-shared'
import { CreditConfiguracionDto } from '../proveedores/credito.dto'

export class ClienteMultimediaDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsString()
  nombre!: string
  @IsString()
  url!: string
}

export class CiudadDto {
  @IsNumber()
  id!: number | null

  @IsString()
  descripcion!: string

  @IsString()
  identificacion!: string

  @IsOptional()
  @IsNumber()
  paisId!: number | null

  @IsOptional()
  @IsNumber()
  municipioId!: number | null
}

export class ColoniaDto {
  @IsNumber()
  id!: number | null

  @IsString()
  descripcion!: string
}

export class EstadoDto {
  @IsNumber()
  id!: number | null

  @IsString()
  descripcion!: string
}

export class ClienteDireccionDto {
  @IsNumber()
  clienteId?: number

  @IsString()
  id?: number

  @IsString()
  calle!: string

  @IsNumber()
  numeroInterior?: number | null

  @IsNumber()
  numeroExterior!: number | null

  @IsString()
  referencias!: string

  @IsString()
  entreCalles!: string

  @IsBoolean()
  principal!: boolean

  @ValidateNested()
  colonia!: ColoniaDto

  @ValidateNested()
  ciudad!: CiudadDto

  @ValidateNested()
  estado!: EstadoDto

  @IsString()
  codigoPostal!: string

  @IsOptional()
  @IsString()
  busqueda?: string
}

export class ClienteCreditoDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsOptional()
  @IsNumber()
  clienteId?: number

  @IsBoolean()
  excederCredito!: boolean

  @IsNumber()
  limiteCredito!: number | null

  @IsDateString()
  fechaCorte!: Date | null

  @IsNumber()
  plazos!: number | null

  @IsBoolean()
  activo!: boolean
}

export class DatosFiscalDto {
  @IsOptional()
  id?: number

  @IsEmail()
  correo!: string

  @IsOptional()
  @ValidateNested()
  @Type(() => DireccionDto)
  direccion!: DireccionDto | null

  @IsString()
  rfc!: string

  @IsBoolean()
  principal!: boolean
}

export class ClienteDto {
  @IsOptional()
  @IsNumber()
  id!: number | null

  //Es el nombre del primer contacto o si es una empresa la razon social
  @IsString()
  razonSocial!: string

  //nick name del cliente o nombre corto de la empresa
  @IsOptional()
  @IsString()
  alias!: string

  @IsOptional()
  @ValidateNested()
  @Type(() => ProspectoTelefonoDto)
  telefono?: ProspectoTelefonoDto

  @IsOptional()
  @IsEmail()
  correo?: string

  @IsOptional()
  @IsEmail()
  idioma?: string

  @ValidateNested({ each: true })
  @Type(() => DireccionDto)
  direcciones!: DireccionDto[]

  @IsBoolean()
  persona!: boolean

  @IsOptional()
  @ValidateNested()
  credito!: CreditConfiguracionDto | null

  @IsOptional()
  @ValidateNested({ each: true })
  @Type(() => ContactoDto)
  contactos?: ContactoDto[]

  @IsOptional()
  @ValidateNested()
  @Type(() => DatosFiscalDto)
  datosFiscales?: DatosFiscalDto | null

  @IsOptional()
  uid?: string

  @IsOptional()
  contrato_cliente?: { titular: boolean }
}

export class ClienteTelefonoDto {
  @IsString()
  telefono!: string

  @IsString()
  lada!: string

  @IsString()
  codigoPais!: string

  @IsOptional()
  @IsNumber()
  id!: number
}

export class ClienteCorreoDto {
  @IsString()
  correo!: string

  @IsOptional()
  @IsNumber()
  id?: number
}

export class ClienteInformacionDto {
  @IsOptional()
  @IsNumber()
  id?: number

  @IsNumber()
  uid!: string

  @IsString()
  nombre!: string

  @IsOptional()
  @ValidateNested()
  @Type(() => ClienteCorreoDto)
  correo?: ClienteCorreoDto

  @IsOptional()
  @ValidateNested()
  @Type(() => ClienteTelefonoDto)
  telefono?: ClienteTelefonoDto

  @IsOptional()
  file?: File | null

  @IsOptional()
  @ValidateNested()
  @Type(() => ClienteMultimediaDto)
  multimedia?: ClienteMultimediaDto
}

export class ClienteConfiguracionSeccionesDto {
  @IsBoolean()
  direcciones!: boolean

  @IsBoolean()
  cuentasPagar!: boolean

  @IsBoolean()
  datosFiscales!: boolean

  @IsBoolean()
  preciosEspeciales!: boolean

  @IsBoolean()
  creditos!: boolean

  @IsBoolean()
  empresa!: boolean

  @IsBoolean()
  guest!: boolean
}

export class ClienteSeccionesDto {
  @IsString()
  descripcion!: string

  @IsString()
  url!: string

  icon!: any
}
