import { Type } from 'class-transformer'
import { IsDate, IsNumber, IsOptional, IsString } from 'class-validator'

export class ActividadDetalleDto {
  @IsString()
  inmueble!: string

  @IsString()
  mensaje!: string

  @IsNumber()
  tipo!: number

  @IsString()
  url!: string
}

export class ActividadesDto {
  @IsNumber()
  id!: number

  @IsString()
  actividad!: string

  @IsDate()
  @Type(() => Date)
  fecha!: Date

  @IsOptional()
  @IsString()
  nota?: string

  @IsOptional()
  @Type(() => ActividadDetalleDto)
  detalle?: ActividadDetalleDto
}
