import { IsNumber, IsString } from 'class-validator'

export class NotasParamsDto {
  @IsNumber()
  contactoId!: number
}

export class NotasDto {
  fecha!: Date

  @IsNumber()
  agenteId!: number

  @IsNumber()
  contactoId!: number

  @IsString()
  nota!: string
}
